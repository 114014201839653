export async function handleWebhook(url, data) {
  const body = {
    email: data.email,
    name: data.full_name,
    marketing: data.marketing,
    news: data.news,
  };

  const headers = {
    Accept: "application/json; charset=utf-8",
    "Content-Type": "application/json; charset=UTF-8",
  };

  try {
    const response = await fetch(url, {
      method: "POST",
      headers: headers,
      body: JSON.stringify(body),
    });

    // Check if response is JSON
    const contentType = response.headers.get("content-type");
    if (contentType && contentType.includes("application/json")) {
      const data = await response.json();
      console.log("Success:", data);
      return data;
    } else {
      // Handle non-JSON responses
      const text = await response.text();
      return text;
    }
  } catch (error) {
    console.error("Error:", error);
    throw error;
  }
}
