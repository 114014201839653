import React from "react";
import tw from "twin.macro";
import styled from "styled-components";
import { Container as ContainerBase } from "components/misc/Layouts.js";

import logo from "../../images/logo.svg";
import { ReactComponent as FacebookIcon } from "../../images/facebook-icon.svg";
import { ReactComponent as TwitterIcon } from "../../images/twitter-icon.svg";
import { ReactComponent as YoutubeIcon } from "../../images/youtube-icon.svg";

const Container = tw(ContainerBase)`bg-[var(--footer-theme)] text-gray-100`;
const Content = tw.div`max-w-screen-xl mx-auto py-20 lg:py-24`;

const Row = tw.div`flex items-center justify-center flex-col px-8`;

const LogoContainer = tw.div`flex items-center justify-center md:justify-start`;
const LogoImg = tw.img`w-24`;
const LogoText = tw.h5`ml-2 text-2xl font-black tracking-wider`;

const LinksContainer = tw.div`mt-8 font-medium flex flex-wrap justify-center items-center flex-col sm:flex-row`;
const Link = tw.a`border-b-2 border-transparent hocus:text-gray-300 hocus:border-gray-300 pb-1 transition duration-300 mt-2 mx-4`;

const SocialLinksContainer = tw.div`mt-10`;
const SocialLink = styled.a`
  ${tw`cursor-pointer inline-block text-gray-100 hover:text-gray-500 transition duration-300 mx-4`}
  svg {
    ${tw`w-5 h-5`}
  }
`;

const TextContainer = tw.div`text-center md:mx-3 lg:mx-6 md:w-6/12 py-4 flex flex-col justify-between`;

const CopyrightText = tw.p`text-center mt-10 font-medium tracking-wide text-sm text-gray-100`;
export default ({
  copytights = "© 3000. All Rights Reserved.",
  logo,
  logoAlt = "",
  logoAltText = "Company Logo",
  facebookLink = "",
  XLink = "",
  youtubeLink = "",
  footerText = `Estatia <br /> Barlow Bank Farm, <br /> 5 Slacks Lane, <br /> Pilsley,<br /> Chesterfield S45 8HH <br />t: 07565112990`,
}) => {
  return (
    <Container>
      <Content>
        <Row>
          <LogoContainer>
            <LogoImg src={logo} alt={logoAltText} />
            <LogoText>{logoAlt}</LogoText>
          </LogoContainer>
          <LinksContainer>
            {/* <Link href="#">Home</Link> */}
            {/* <Link href="#features">Features</Link>
            <Link href="#contact-us">Contact Us</Link>
            <Link href="#">Book a Demo</Link> */}
            {/* <Link href="#">Reviews</Link> */}
          </LinksContainer>
          {/* <SocialLinksContainer>
            {facebookLink && (
              <SocialLink href={facebookLink}>
                <FacebookIcon />
              </SocialLink>
            )}
            {XLink && (
              <SocialLink href={XLink}>
                <TwitterIcon />
              </SocialLink>
            )}
            {youtubeLink && (
              <SocialLink href={youtubeLink}>
                <YoutubeIcon />
              </SocialLink>
            )}
          </SocialLinksContainer> */}
          <TextContainer
            dangerouslySetInnerHTML={{
              __html: footerText,
            }}
          ></TextContainer>
          <CopyrightText>{copytights}</CopyrightText>
        </Row>
      </Content>
    </Container>
  );
};
