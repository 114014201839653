import React from "react";
import tw from "twin.macro";
const PolicyContainer = tw.div`max-w-screen-xl mx-auto py-8 px-4`;
const Heading2 = tw.h2`text-2xl font-bold mt-8 mb-4`;
const Heading3 = tw.h3`text-xl font-bold mt-6 mb-3`;
const Heading4 = tw.h4`text-lg font-bold mt-4 mb-2`;
const Paragraph = tw.p`mb-4`;
const List = tw.ul`list-disc pl-6 mb-4`;
const ListItem = tw.li`mb-2`;
const Link = tw.a`text-blue-600 hover:text-blue-800 underline`;

const PolicyText = () => {
  return (
    <PolicyContainer>
      <Paragraph>
        This Cookie Policy sets out the basis on which we, use cookies and
        similar technologies on our website,{" "}
        <Link href="https://estatia.co.uk">https://estatia.co.uk</Link> (our
        website).
      </Paragraph>

      <Paragraph>
        We may update this Cookies Policy from time to time in order to keep you
        fully informed about our latest practices involving cookies and similar
        information-gathering technologies on our website. You should check this
        Cookies Policy each time you visit our website in order to find out
        whether our use of cookies and similar technologies has changed.
      </Paragraph>

      <Paragraph>
        We recommend that you print a copy of this Cookies Policy for your
        records.
      </Paragraph>

      <Heading2>Contents</Heading2>
      <List>
        <ListItem>
          <Link href="#about_cookies">About cookies</Link>
        </ListItem>
        <ListItem>
          <Link href="#types_of_cookies">Types of cookies used</Link>
        </ListItem>
        <ListItem>
          <Link href="#essential_cookies">Essential cookies</Link>
        </ListItem>
        <ListItem>
          <Link href="#functional_cookies">Functional cookies</Link>
        </ListItem>
        <ListItem>
          <Link href="#analytical_cookies">
            Analytical or performance cookies
          </Link>
        </ListItem>
        <ListItem>
          <Link href="#targeting_cookies">Targeting cookies</Link>
        </ListItem>
        <ListItem>
          <Link href="#third_party_cookies">Third party cookies</Link>
        </ListItem>
        <ListItem>
          <Link href="#web_beacons">Web beacons</Link>
        </ListItem>
        <ListItem>
          <Link href="#how_to_accept_reject">
            How to accept or reject cookies
          </Link>
        </ListItem>
        <ListItem>
          <Link href="#copyright">Copyright, credit and logo</Link>
        </ListItem>
      </List>

      <h2 id="about_cookies">About cookies</h2>

      <Paragraph>
        Cookies are data files that are sent between web servers and web
        browsers, processor memory or hard drives (clients) to recognise a
        particular user’s device when the user accesses a website. They are used
        for a range of different purposes, such as customising a website for a
        particular user, helping a user navigate a website, improving that
        user’s website experience, and storing that user’s preferences and login
        information.
      </Paragraph>

      <Paragraph>
        Cookies can be classified as either ‘session’ or ‘persistent’ cookies.
        Session cookies are placed on your browser when you access a website and
        last for as long as you keep your browser open. They expire when you
        close your browser.
      </Paragraph>

      <Paragraph>
        Persistent cookies are placed on your computer when you access a website
        and expire at a fixed point in time or if you manually delete them from
        your browser, whichever occurs first.
      </Paragraph>

      <Paragraph>
        Cookies will be set either by our website domain or by third party
        domains on our behalf. Cookies set by us on our website domain are
        referred to as ‘first party’ cookies. Cookies set by third party domains
        or set on or via our website domain on behalf of third parties are
        referred to as ‘third party’ cookies.
      </Paragraph>

      <Paragraph>
        Cookies do not usually contain personal information. Cookies may,
        however, be used in combination with other information to identify you.
      </Paragraph>

      <Paragraph>
        We use first party and third party cookies on our website, and both
        session and persistent cookies.
      </Paragraph>

      <Paragraph>
        If you require further information about cookies in general, please
        visit{" "}
        <Link href="www.allaboutcookies.org">www.allaboutcookies.org</Link>
      </Paragraph>

      <h2 id="types_of_cookies">Types of cookies used</h2>

      <Paragraph>
        We use the following types of cookies on our website:
      </Paragraph>

      <Heading3 id="essential_cookies">Essential cookies</Heading3>

      <Paragraph>
        These are cookies which are necessary in order for us to run our website
        and its essential functions. We use essential cookies for the following
        purposes on our website:
      </Paragraph>
      <List>
        <ListItem>to administer our website.</ListItem>
        <ListItem>
          to ensure our website functions properly when you interact with it.
        </ListItem>
        <ListItem>
          for processing payments for goods and services you purchase on or via
          our website.
        </ListItem>
        <ListItem>
          for logging into password protected areas or accessing other secure
          areas of our website.
        </ListItem>
        <ListItem>
          for remembering information you have inserted in a form on our
          website.
        </ListItem>
      </List>

      <Heading3 id="functional_cookies">Functional cookies</Heading3>

      <Paragraph>
        These are cookies that store information about your preferences and your
        previous interactions with our website, and recognise you whenever you
        return to a website, in order to improve your website experience and
        personalise it for you. We use functional cookies for the following
        purposes on our website:
      </Paragraph>
      <List>
        <ListItem>to recognise you when you return to our website.</ListItem>
        <ListItem>to improve your experience of our website.</ListItem>
        <ListItem>
          to recognise whether you have accepted the use of cookies on our
          website.
        </ListItem>
        <ListItem>
          to recognise the type of browsing device you are using, including your
          browser and browser settings, for the purpose of displaying our
          website to you in an appropriate format.
        </ListItem>
        <ListItem>for the purpose of providing our live chat service.</ListItem>
        <ListItem>
          to recognise when you watch videos on our website and which videos you
          watch.
        </ListItem>
      </List>

      <Heading3 id="analytical_cookies">
        Analytical or performance cookies
      </Heading3>

      <Paragraph>
        Analytical cookies track and gather data about what a user does on a
        website. We use analytical cookies for the following purposes on our
        website:
      </Paragraph>
      <List>
        <ListItem>
          to analyse users’ access to and use of our website and its features.
        </ListItem>
        <ListItem>
          to improve our website, including its usability, functionality,
          content and user experience.
        </ListItem>
      </List>
      <Paragraph>
        The information we collect using analytical cookies is collected on an
        anonymised basis.
      </Paragraph>

      <Heading3 id="targeting_cookies">Targeting cookies</Heading3>

      <Paragraph>
        Targeting cookies record information about your visit to and use of our
        website, for advertising purposes. We use the targeting cookies for the
        following purposes on our website:
      </Paragraph>
      <List>
        <ListItem>
          to track whether you have come to us via an advertisement we have
          placed on a search engine results page or another website and to
          record information relating to how you came to us such as your
          geographical location when you accessed our website, the time of day
          you visited and the device you were using.
        </ListItem>
      </List>

      <Heading4>First party cookies</Heading4>
      <Paragraph>
        First party cookies used in relation to our website include:
      </Paragraph>
      <Paragraph>
        Cookie Cookie Name Purpose More info Google Analytics _ga _gid
        _gat_UA-51084407-1 These cookies are used to collect information about
        how visitors use our site. We use the information to compile reports and
        to help us improve the site. The cookies collect information in an
        anonymous form, including the number of visitors to the site, where
        visitors have come to the site from and the pages they visited. Google
        Analytics Cookie Usage on Websites (includes list of cookies used)
      </Paragraph>
      <Paragraph>Types of cookies used by Google</Paragraph>

      <Heading3 id="third_party_cookies">Third party cookies</Heading3>
      <Paragraph>
        Third parties may use cookies on, via, or in relation to our website in
        order to analyse your use of our website and/or to display
        advertisements (including third party advertisements) to you and for
        advertisement conversion tracking such as Google Ads, if used.
      </Paragraph>
      <Paragraph>
        Third party cookies used in relation to our website include:
      </Paragraph>
      <Paragraph>
        Cookie Cookie Name Purpose More info Google Ads, Remarketing and
        Advertising gwcc 1P_JAR AID APISID CONSENT DSID DV HSID IDE NID SAPISID
        SID SIDCC SSID TAID UULE These cookies are used for the purposes of
        ad-related products by Google. This includes conversion cookies to help
        us determine how many times users who click on our Google Adwords ads
        end up purchasing our products.
      </Paragraph>
      <Paragraph>
        We also use cookies named ‘AID’, ‘DSID’ and ‘TAID’, which are used to
        link your activity across devices if you’ve previously signed in to your
        Google Account on another device. We do this to coordinate that the ads
        you see across devices and measure conversion events. Types of cookies
        used by Google
      </Paragraph>
      <Paragraph>Cookie: Definition by Google</Paragraph>
      <Paragraph>
        We cannot be responsible for the ways in which third parties use
        information about you which they gather from their use of cookies.
      </Paragraph>

      <h2 id="how_to_accept_reject">How to accept or reject cookies</h2>

      <Paragraph>
        The easiest way to accept or reject some or all cookies used by a
        website is to adjust your browser settings. If you do not know how to do
        this, the links below set out information about how to change your
        browser settings for some of the most commonly used web browsers:
      </Paragraph>
      <List>
        <ListItem>
          Google Chrome:{" "}
          <Link href="https://support.google.com/chrome/answer/95647?hl=en-GB">
            https://support.google.com/chrome/answer/95647?hl=en-GB
          </Link>
        </ListItem>
        <ListItem>
          Mozilla Firefox:{" "}
          <Link href="https://support.mozilla.org/en-US/kb/delete-browsing-search-download-history-firefox?redirectlocale=en-US&redirectslug=Clear+Recent+History">
            https://support.mozilla.org/en-US/kb/delete-browsing-search-download-history-firefox?redirectlocale=en-US&redirectslug=Clear+Recent+History
          </Link>
        </ListItem>
        <ListItem>
          Microsoft Internet Explorer:{" "}
          <Link href="https://support.microsoft.com/en-us/help/278835/how-to-delete-cookie-files-in-internet-explorer">
            https://support.microsoft.com/en-us/help/278835/how-to-delete-cookie-files-in-internet-explorer
          </Link>
        </ListItem>
        <ListItem>
          Apple Safari:{" "}
          <Link href="https://support.apple.com/kb/PH5042?locale=en_US">
            https://support.apple.com/kb/PH5042?locale=en_US
          </Link>
        </ListItem>
      </List>

      <Paragraph>
        Some browsers, such as Chrome and Firefox, allow you to change your
        settings to browse in ‘incognito’ mode, limiting the amount of data
        placed on your machine and automatically deleting any persistent cookies
        placed on your device when you finish your browsing session. There are
        also third party applications which you can add to your browser to block
        or manage cookies.
      </Paragraph>

      <Paragraph>
        You can opt out of Google Analytics tracking by visiting{" "}
        <Link href="http://tools.google.com/dlpage/gaoptout">
          http://tools.google.com/dlpage/gaoptout
        </Link>
      </Paragraph>

      <Paragraph>
        You are welcome to block the use of some or all of the cookies we use on
        our website. However, please be aware that doing so may impair our
        website and its functionality or may even render some or all of it
        unusable.
      </Paragraph>

      <Paragraph>
        You can find more detailed information about cookies and adjusting your
        browser settings by visiting{" "}
        <Link href="www.allaboutcookies.org">www.allaboutcookies.org</Link>
      </Paragraph>

      <h2 id="copyright">Copyright, credit and logo</h2>

      <Paragraph>
        This Cookies Policy is based on a General Data Protection Regulation
        (Regulation (EU) 2016/769) compliant template provided by GDPR Privacy
        Policy. For further information, please visit{" "}
        <Link href="https://gdprprivacypolicy.org">
          https://gdprprivacypolicy.org
        </Link>
      </Paragraph>

      <Paragraph>
        The copyright in this Cookies Policy is either owned by, or licensed to,
        us and is protected by copyright laws around the world and copyright
        protection software. All intellectual property rights in this document
        are reserved. Where we display the GDPR Privacy Policy logo on our
        website, this is used to indicate that we have adopted a privacy policy
        template provided by GDPR Privacy Policy as the basis for this Privacy
        Policy.
      </Paragraph>
    </PolicyContainer>
  );
};

export default PolicyText;
